/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ScheduleEventType =
  typeof ScheduleEventType[keyof typeof ScheduleEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduleEventType = {
  DEPOSIT_PAYMENT_DUE: 'DEPOSIT_PAYMENT_DUE',
  WITHDRAWAL_PAYMENT_DUE: 'WITHDRAWAL_PAYMENT_DUE',
  DISTRIBUTION_PAYMENT_DUE: 'DISTRIBUTION_PAYMENT_DUE',
  TERM_PRODUCT_DISTRIBUTIONS: 'TERM_PRODUCT_DISTRIBUTIONS',
  CONFIRM_PLATFORM_ORDERS: 'CONFIRM_PLATFORM_ORDERS',
  COMPLETE_PLATFORM_ORDERS: 'COMPLETE_PLATFORM_ORDERS',
  INFORM_PLATFORM_DISTRIBUTIONS: 'INFORM_PLATFORM_DISTRIBUTIONS',
  INFORM_PLATFORM_INTEREST: 'INFORM_PLATFORM_INTEREST',
  REFRESH_PLATFORM_BANK_PRODUCTS: 'REFRESH_PLATFORM_BANK_PRODUCTS',
  REFRESH_PLATFORM_POSITIONS: 'REFRESH_PLATFORM_POSITIONS',
  EXECUTE_BANK_ORDERS: 'EXECUTE_BANK_ORDERS',
  INFORM_PLATFORM_RECONCILIATION: 'INFORM_PLATFORM_RECONCILIATION',
  ACCRUALS_AND_REALISATIONS: 'ACCRUALS_AND_REALISATIONS',
  CONFIRM_CUSTOMER_UPDATES: 'CONFIRM_CUSTOMER_UPDATES',
  STATEMENTS: 'STATEMENTS',
  INFORM_PLATFORM_ANALYTICS: 'INFORM_PLATFORM_ANALYTICS',
  FIXED_TERM_RECONCILIATION: 'FIXED_TERM_RECONCILIATION',
  INSTANT_NOTICE_RECONCILIATION: 'INSTANT_NOTICE_RECONCILIATION',
  BANK_ORDER_PAYMENT_DUE: 'BANK_ORDER_PAYMENT_DUE',
  BANK_DISTRIBUTION_PAYMENT_DUE: 'BANK_DISTRIBUTION_PAYMENT_DUE',
} as const;
